import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import CarouselLeftButton from "../elements/carousel-left-button";
import CarouselRightButton from "../elements/carousel-right-button";

// Carousel breapoints
const responsive = {
  superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1280 },
      items: 8.5,
  },
  desktop: {
      breakpoint: { max: 1280, min: 1024 },
      items: 6,
  },
  tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 2.8
  },
  mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
  }
};

// Initialize Smooth Scroll
if (typeof window !== "undefined") {
    require("smooth-scroll")('a[href*="#"]');
  }

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide, totalItems, slidesToShow } } = rest;

    const slideEnd = totalItems - slidesToShow;

  return (
      
      <div className={`carousel-button-group ${totalItems < 5 && 'hide-arrows-on-desktop'}`}>
          <div className={'flex items-center justify-between w-full'}>
              <div className={`basis-auto ${currentSlide === 0 ? 'hidden' : 'block'}`}>
                  <CarouselLeftButton class={'carousel-small'} onClick={() => previous()}/>
              </div>
              <div className={`basis-auto ${currentSlide === slideEnd ? 'hidden' : 'block'}`}>
                  <CarouselRightButton class={'carousel-small'} onClick={() => next()}/>
              </div>
          </div>
      </div>
  )

};

const ScrollNav = (props) => {

  const list = props.content.map((workCategory, index) => {
      return {
          id: index,
          name: workCategory.node.title,
          slug: workCategory.node.slug,
      }
  });
  
  const [active, setActive] = useState(null);
  
  return (
    <section className="pt-24 pb-10 scroll-nav-section">
      <div className="container">
        <nav>
          <ul className="flex">
            <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                infinite={false}
                autoPlay={false}
                autoPlaySpeed={1000}
                centerMode={false}
                keyBoardControl={true}
                containerClass="carousel-container flex w-full"
                renderButtonGroupOutside={true}
                arrows={false}
                customButtonGroup={<ButtonGroup />}
                itemClass=""
            >
              {list.map((item, index) => {
                return (
                  <li key={index}>
                    <a
                      className={`list-item ${active === item.id && "active"}`}
                      href={`#${item.slug}`}
                      key={item.id}
                      onClick={() => setActive(item.id)}
                    >
                      {item.name}
                    </a>
                  </li>
                )}
              )}
            </Carousel>
          </ul>
        </nav>
      </div>
    </section>
  )
}

export default ScrollNav