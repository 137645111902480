import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import { componentMap, DataProvider } from "../../contentful/data-provider";
// import { Helmet } from "react-helmet";
import ScrollNav from "../../components/sections/work-scroll-nav-section";
import Seo from "../../components/seo";

const WorksPage = ({ data }) => {
	const dataProvider = new DataProvider(data);
	const title = data.allContentfulPage.edges[0].node.title;

	return (
		<Layout dataProvider={dataProvider}>
			<Seo dataProvider={dataProvider} title={`Glassview | ${title}`} />
			<main className={"pt-24 lg:pt-24 pb-20"}>
				{dataProvider.getCurrentPageSections().map((section, index) => {
					return (
						<>
							{index === 2 && <ScrollNav content={data.allContentfulWorkCategory.edges} />}
							{React.createElement(componentMap[section.type], {
								content: dataProvider.getElementById(section.id, section.locale),
								key: index,
							})}
						</>
					);
				})}
			</main>
		</Layout>
	);
};

export default WorksPage;

export const query = graphql`
	query ($locale: String!, $contentfulId: String!) {
		allContentfulPage(filter: { contentful_id: { eq: $contentfulId }, node_locale: { eq: $locale } }) {
			edges {
				node {
					...CurrentPageDetails
				}
			}
		}
		allContentfulEntry {
			edges {
				node {
					...AllEntryDetails
				}
			}
		}
		allContentfulMenu(filter: { node_locale: { eq: $locale } }) {
			edges {
				node {
					...Menus
				}
			}
		}
		allContentfulWorkCategory(filter: { node_locale: { eq: $locale } }, sort: { fields: slug }) {
			edges {
				node {
					title
					slug
				}
			}
		}
	}
`;

//comentedhead
